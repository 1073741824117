<template>
  <div class="net-dec">
    <div class="section1">
      <img src="../../assets/img/product2/section_img1.png" alt="">
    </div>
    <div class="section2">
      <img src="../../assets/img/product2/section_img2.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: "magdec",
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.net-dec {
  .section1 {
    height: 670px;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    img {
      margin-top: 100px;
    }
  }

  .section2 {
    background: url(~@/assets/img/product2/bg2.png);
    background-position: center;
    height: 641px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    img {
      margin-top: 100px;
    }
  }
}
</style>
